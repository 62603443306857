<template>
  <div class="share_speaker">
    <van-nav-bar
        title
        left-text
        left-arrow
        :fixed="true"
        :border="false"
        :safe-area-inset-top="false"
        @click-left="onClickLeft"
      />
    <div class="cont">
      <!-- <img class="avator" src="../../public/statics/images/share/avator.png"> -->
      <img class="avator" :src="adddom(myInfo.thumb)" alt />
      <!-- <h1>{{myInfo.show_role && myInfo.show_role.name}}</h1> -->
      <h1>{{myInfo.name}}</h1>
      <h2>我已成为运城发声人<br/><span class="left">我为家乡发声</span><span>我为运城代言</span></h2>
      <img class="code" src="../../public/statics/images/share/code.png">
      <h3>扫码加入</h3>
      <!-- <h2>我在运城发声人中获得<br/>「{{myInfo.show_role && myInfo.show_role.name}}」扫码和我一起参加</h2> -->
    </div>
    <van-overlay :show="loadShow" style="z-index:900;">
      <div class="wld-wrapper" @click.stop>
        <div class="wld-block">
          <!-- <van-loading type="spinner" /> -->
          <van-loading size="24px" vertical>加载中...</van-loading>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
// @ is an alias to /src
import indexFooter from './footer.vue'
import Cookies from "js-cookie";
export default {
  name: 'share_speaker',
  setup() {
  },
  data () {
    return {
      loadNum: 0,
      loadShow: false,
      name: '',
      message: '',
      fileList: [],
      myInfo: {}
	  }
  },
  components: {
    indexFooter
  },
  created () {
    this.loadNum++
    this.loadShow = true
    this.getInfo();
	},
  methods: {
    onClickLeft(){
      // history.back()
      this.toPath('/my',{})
    },
    shareFun() {
        var that = this;
        var tempurl = location.href;
        const url = location.href.split("#")[0];
        console.log("Iframe ---share----url=" + url);
        let routeData = that.$router.resolve({
          path: '/share',
          query: {
            speaker_id: that.myInfo.id
          }
        })
        const shareUrl = that.$publicData.domain + routeData.href
        console.log(shareUrl)
        that.$api.index.getShare({url: tempurl}).then(res => {
            if (res.code == 200) {
                that.shareConfig = res.result;
                wx.config({
                    debug: that.shareConfig.debug,
                    beta: that.shareConfig.beta,
                    jsApiList: that.shareConfig.jsApiList,
                    appId: that.shareConfig.appId,
                    nonceStr: that.shareConfig.nonceStr, // 随机串
                    timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
                    signature: that.shareConfig.signature // 签名
                });
                // console.log('Iframe ---微信分享设置---开始');
                // 分享
                wx.ready(function () {
                    var shareDataA = {
                        // title: that.$publicData.shareTitle, // 分享标题
                        title: '我已成为运城发声人，我为家乡代言', // 分享标题
                        desc: that.$publicData.shareDesc, // 分享描述
                        link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: that.$publicData.shareImg, // 分享图标
                        success: function () {
                        },
                        cancel: function (e) {
                        }
                    };

                    var shareDataB = {
                        // title: that.$publicData.shareTitle, // 分享标题
                        title: '我已成为运城发声人，我为家乡代言', // 分享标题
                        link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: that.$publicData.shareImg, // 分享图标
                        success: function () {
                        },
                        cancel: function (e) {
                        }
                    };
                    wx.updateAppMessageShareData(shareDataA); // 分享给朋友
                    wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
                    // console.log('Iframe ---微信分享设置---结束');
                });
            }
            
        });
    },
    getInfo() {
      let that = this;
      let ps = {
        speaker_id: this.$route.query.speaker_id
      };
      that.$api.my.speakerBaseInfo(ps).then(function(response) {
        if (response.code === 200) {
          that.loadNum--
          if (that.loadNum==0){
            that.loadShow = false
          }
          // console.log(response.result)
          that.myInfo = response.result;
          that.shareFun()
        }
      });
    },
    adddom(src) {
      if (src == "" || src == undefined || src == null) {
        console.log("src地址有问题");
        return "";
      }
      if (src.substr(0, 4).toLowerCase() === "http") {
        return src;
      }
      return this.$imgurl + src;
    },
    toPath (url, obj) {
      if (this.$route.fullPath == url) {
        return
      }
      if (this.$route.path == url) {
        this.$router.push({
          path: url,
          query: obj
        })
        window.location.reload()
        return
      }
      this.$router.push({
        path: url,
        query: obj
      })
    }
  }
}
</script>
<style>
.share_speaker .van-nav-bar .van-icon {
  color: #fff;
}
.share_speaker .van-nav-bar--fixed{
  background: transparent;
}
.share_speaker .van-nav-bar{
  background: transparent;
}
</style>
